<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div class="font-size-h2 bold-text">Feedback</div>
        <p class="text-muted">
          Share your feedback with the developers of this app
        </p>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          filled
          label="Your name"
          v-model="formValues.name"
          v-validate="'required|min:3|max:20'"
          v-bind="veeValidate('Name', '')"
          placeholder="What is your name?"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          filled
          label="Your email"
          v-validate="'email'"
          v-bind="veeValidate('Email', '')"
          placeholder="And your email? (Optional)"
          v-model="formValues.email"
          hint="if you require a response from the developers"
          persistent-hint
        ></v-text-field>
      </v-col>
      <v-col
        :class="$vuetify.breakpoint.smAndDown ? 'pt-0 pb-0' : ''"
        cols="12"
        md="12"
      >
        <v-textarea
          filled
          v-model="formValues.message"
          v-validate="'max:600'"
          v-bind="veeValidate('Message', '')"
          rows="4"
          counter="600"
          label="Message"
          hide-details="auto"
          auto-grow
          placeholder="Do you have any feedback for us?"
          persistent-hint
        >
        </v-textarea>
        <div v-if="errorMessage" class="pt-0 error--text">
          {{ errorMessage }}
        </div>
      </v-col>
      <v-col class="pt-0 mb-0 pb-0" cols="12">
        <v-row>
          <v-col cols="6" md="6">
            <v-rating
              dense
              hover
              label="Rate"
              v-model="formValues.rating"
              length="5"
              size="23"
              value="5"
            ></v-rating>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn
              @click="sendReleaseFeedback"
              :color="messageSent ? 'success' : 'primary'"
              :loading="loading"
              class="text-right text-transform-none"
            >
              <div v-if="!messageSent">Leave feedback</div>
              <div v-else>
                <v-icon left>mdi-check</v-icon>
                Feedback sent
              </div>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <p class="text-muted mt-0">
              By sharing your feedback, your location (country & city) and
              device information (model & version) will be shared with the
              developers of this app to help them to address your feedback
              better.
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { SEND_INSTALL_FEEDBACK } from "@/store/releases/releases.module";
import veeValidate from "@/mixins/veeValidate";
import { mapGetters } from "vuex";
export default {
  mixins: [veeValidate],
  props: {
    releaseInfo: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      messageSent: false,
      loading: false,
      errorMessage: null,
      formValues: {
        name: "",
        email: "",
        message: "",
        rating: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      isAuthenticated: "isAuthenticated",
    }),
  },
  created() {
    if (this.isAuthenticated) {
      this.formValues.name = this.currentUser.name;
      this.formValues.email = this.currentUser.email;
    }
  },
  methods: {
    async sendReleaseFeedback() {
      this.errorMessage = null;
      if (await this.validateAllFields()) {
        if (!this.formValues.message && !this.formValues.rating) {
          this.errorMessage =
            "Please provide your message or rating to submit your feedback";
          return;
        }

        if (this.releaseInfo && this.releaseInfo.id) {
          this.formValues.release_id = this.releaseInfo.id;
        }
        this.messageSent = false;
        if (this.loading) return;
        this.loading = true;
        const uid = this.$route.params.uid;
        this.$store
          .dispatch(SEND_INSTALL_FEEDBACK, {
            uid,
            ...this.formValues,
          })
          .then(() => {
            this.loading = false;
            this.messageSent = true;
            this.resetForm();
            this.$validator.errors.clear();
            setTimeout(() => {
              this.messageSent = false;
              this.$validator.errors.clear();
            }, 3000);
          })
          .catch((err) => {
            this.loading = false;
            this.errorMessage = err.message;
          });
      }
    },
    resetForm() {
      this.formValues = {
        name: "",
        email: "",
        message: "",
        rating: 0,
      };
      setTimeout(() => {
        this.$validator.errors.clear();
      }, 500);
    },
  },
};
</script>

<style scoped></style>
