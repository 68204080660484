<template>
  <v-row>
    <v-col class="titleFont text-center mt-5 bold-text black--text" cols="12">
      Update on an {{ android ? "iOS" : "Android" }} Release
    </v-col>
    <v-col class="titleFont bold-text" cols="12">
      <v-text-field
        id="editAppName"
        v-model="form.name"
        counter="27"
        v-validate="'required|max:27'"
        v-bind="veeValidate('name', '')"
        placeholder="App name"
        filled
      >
        <template #label>
          <span class="bodyFont text-muted">Please let us know your name</span>
        </template>
      </v-text-field>
    </v-col>
    <v-col class="titleFont bold-text" cols="12">
      <v-text-field
        id="editAppName"
        v-model="form.email"
        v-validate="'required|email'"
        v-bind="veeValidate('email', '')"
        placeholder="Email"
        filled
      >
        <template #label>
          <span class="bodyFont text-muted"
            >Let us get back to you and keep your update</span
          >
        </template>
      </v-text-field>
    </v-col>
    <v-col class="titleFont bold-text" cols="12">
      <v-textarea
        v-model="form.message"
        v-validate="''"
        rows="4"
        v-bind="veeValidate('message', '')"
        placeholder="Message"
        filled
      >
        <template #label>
          <span class="bodyFont text-muted"
            >Let us know what's on your mind</span
          >
        </template>
      </v-textarea>
    </v-col>
    <v-col cols="12">
      <v-btn
        @click="sendReleaseRequest"
        color="primary"
        depressed
        :loading="loading"
        class="text-transform-none"
        block
      >
        Submit
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import veeValidate from "@/mixins/veeValidate";
import {SUBMIT_SUBSCRIBERS} from "@/store/apps/subscribers.module";
export default {
  mixins: [veeValidate],
  props: {
    android: {
      type: Object,
      default() {
        return null;
      }
    },
    ios: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  data() {
    return {
      loading: false,
      form: {
        type: "request"
      }
    };
  },
  methods: {
    async sendReleaseRequest() {
      this.errorMessage = null;
      if (await this.validateAllFields()) {
        if (this.isAuthenticated) {
          this.form.name = this.currentUser.name;
          this.form.email = this.currentUser.email;
        }
        if (this.releaseInfo && this.releaseInfo.id) {
          this.form.release_id = this.releaseInfo.id;
        }
        this.messageSent = false;
        if (this.loading) return;
        this.loading = true;
        const uid = this.$route.params.uid;
        this.$store
          .dispatch(SUBMIT_SUBSCRIBERS, {
            uid,
            ...this.form
          })
          .then(() => {
            this.loading = false;
            this.messageSent = true;
            this.resetForm();
            this.$validator.errors.clear();
            setTimeout(() => {
              this.messageSent = false;
              this.$validator.errors.clear();
            }, 3000);
          })
          .catch(err => {
            this.loading = false;
            this.errorMessage = err.message;
          });
      }
    },
    resetForm() {
      this.form = {
        name: "",
        email: "",
        message: "",
      };
      setTimeout(() => {
        this.$validator.errors.clear();
      }, 500);
    }
  }
};
</script>

<style scoped></style>
