<template>
  <div style="background-color: #f6f6f6">
    <div class="fill-width align-self-start">
      <v-card
        color="primary"
        flat
        class="d-flex align-center fill-width"
        height="50"
        tile
      >
        <a href="https://testapp.io" class="pa-5">
          <img
            :src="
              fromExpress
                ? '/media/logos/logo_express.png'
                : '/media/logos/logo_light.svg'
            "
            height="30"
            alt
          />
        </a>
      </v-card>
    </div>
    <v-container class="">
      <v-row justify="center" v-if="loading">
        <v-col v-for="i in 4" :key="i" cols="12" md="8" class="mb-4">
          <v-skeleton-loader
            type="list-item-avatar, divider, list-item-three-line, card-heading, image"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row justify="center" class="mb-15" v-else-if="errorMessage">
        <v-col cols="12" md="8">
          <v-card class="pa-10">
            <template>
              <v-col class="text-center titleFont bold-text" cols="12">
                Ops!
              </v-col>
              <v-col cols="12">
                <v-alert label outlined color="red">
                  <span v-html="errorMessage"></span>
                </v-alert>
              </v-col>
              <v-col class="text-center" cols="12">
                Please contact the person who shared this link with you for more
                info
              </v-col>
              <v-col class="text-center" cols="12">
                <img
                  class="mx-auto my-auto"
                  width="260"
                  src="/media/error/not_available.png"
                />
              </v-col>
            </template>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else justify="center" class="mb-15">
        <v-col cols="12" md="8">
          <v-card flat class="px-8">
            <v-row
              v-if="installInfo.settings.status"
              justify="center"
              class="pb-0"
            >
              <v-col cols="12" md="12" class="pb-0">
                <v-alert
                  :icon="
                    installInfo.settings.status == 'coming_soon'
                      ? 'mdi-clock'
                      : 'mdi-alert'
                  "
                  outlined
                  :color="
                    installInfo.settings.status == 'coming_soon'
                      ? 'primary'
                      : 'purple'
                  "
                >
                  <div>
                    <span v-if="!installInfo.settings.custom_message">
                      {{
                        get(installInfo, "settings.status", null) ==
                        "coming_soon"
                          ? "This app will be soon available for public testing!"
                          : "This app is in maintenance mode, please check back later"
                      }}
                    </span>
                    <span v-else>
                      <div>
                        {{ installInfo.settings.custom_message }}
                        <sub>
                          <small
                            >~
                            {{
                              get(
                                installInfo,
                                "settings.developer_contact.name",
                                installInfo.name + " app developers"
                              )
                            }}</small
                          >
                        </sub>
                      </div>
                    </span>
                  </div>
                </v-alert>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12">
                <v-row align="center">
                  <v-col cols="auto" md="8">
                    <v-row align="center">
                      <v-col cols="3">
                        <img
                          :src="getAppImage()"
                          height="100"
                          width="100"
                          :alt="installInfo.name"
                          @error="replaceDefaultAppImage"
                        />

                        <!-- <AppImage
                          :appId="installInfo.image"
                          :image="getAndroidReleaseInfo.image"
                          :size="$vuetify.breakpoint.mdAndUp ? '99' : '60'"
                          :defaultImg="'/media/defaults/icon-100.png'"
                          class="d-flex justify-center align-center"
                        /> -->
                      </v-col>
                      <v-col cols="auto">
                        <div
                          :class="$vuetify.breakpoint.mdAndUp ? 'mb-1' : ''"
                          class="font-size-h2 bold-text"
                        >
                          {{ installInfo.name }}
                        </div>
                        <div
                          v-if="
                            get(
                              installInfo,
                              'settings.developer_contact.name',
                              null
                            )
                          "
                        >
                          <span class="text-muted">By</span>
                          <span>
                            {{
                              get(
                                installInfo,
                                "settings.developer_contact.name",
                                "-"
                              )
                            }}
                          </span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    class="text-center my-auto px-auto"
                    cols="12"
                    lg="4"
                    md="4"
                    sm="4"
                    v-if="mobile === 'unknown'"
                  >
                    <div v-if="!noReleases">
                      <!-- <v-btn
                        @click="qrcodeModal=true"
                        class="text-transform-none text-h5"
                        color="primary"
                        block
                        large
                      >
                        Install
                      </v-btn> -->

                      <v-btn
                        x-large
                        color="primary"
                        block
                        @click="qrcodeModal = true"
                      >
                        <v-icon v-text="'mdi-cellphone-arrow-down'" left></v-icon>
                        Install
                      </v-btn>

                      <div class="mt-2 text-muted">
                        Available for
                        {{
                          getIOSReleaseInfo && getAndroidReleaseInfo
                            ? "both Android & iOS"
                            : getIOSReleaseInfo
                              ? "iOS"
                              : "Android"
                        }}
                      </div>
                    </div>
                    <div v-else>
                      <img
                        class="errImg"
                        :src="
                      get(installInfo, 'settings.status', null)
                        ? '/media/error/maintenance.png'
                        : '/media/error/not_available.png'
                    "
                      />
                      <div class="mt-2 text-muted">No available releases</div>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="8"
                    class="pt-0 mt-0"
                    v-if="
                      installInfo.categories && installInfo.categories.length
                    "
                  >
                    <v-chip
                      class="mr-1 mt-0"
                      x-small
                      v-for="(item, index) in installInfo.categories"
                      :key="index"
                    >
                      {{ item.name }}
                    </v-chip>
                  </v-col>
                  <v-col cols="12" md="8">
                    <vue-readmore
                      :text="
                        installInfo.description
                          ? installInfo.description
                          : !get(installInfo, 'settings.status', null)
                          ? getPlatformStatus()
                            ? 'This app is ready for testing and waiting for your feedback!'
                            : ''
                          : ''
                      "
                      :length="200"
                    ></vue-readmore>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col v-if="noReleases && mobile === 'unknown'" cols="12">
                <div class="text-right mt-3">
                  <div
                    v-if="
                      mobile === 'unknown' &&
                      (getIOSReleaseInfo || getAndroidReleaseInfo)
                    "
                  >
                    <div v-if="!getIOSReleaseInfo || !getAndroidReleaseInfo">
                      Available for
                      {{ getIOSReleaseInfo ? "iOS" : "Android" }}
                      <v-menu bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            text
                            icon
                            small
                            class="text-transform-none mb-2 cursor-pointer"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon small v-text="'mdi-help-circle'"></v-icon>
                          </v-btn>
                        </template>
                        <v-card width="300" class="pa-4">
                          <v-row>
                            <v-col class="text-center" cols="12">
                              <v-row justify="center">
                                <v-col cols="2">
                                  <v-icon
                                    v-text="'mdi-information-outline'"
                                    left
                                  ></v-icon>
                                </v-col>
                                <v-col
                                  class="text-left black--text"
                                  cols="auto"
                                >
                                  This release is only available <br />
                                  for
                                  {{ getIOSReleaseInfo ? "iOS" : "Android" }}
                                </v-col>
                              </v-row>
                            </v-col>
                            <!--                            <v-col cols="12">-->
                            <!--                              <v-divider></v-divider>-->
                            <!--                            </v-col>-->
                            <!--                            <v-col class="text-center" cols="12">-->
                            <!--                              <v-btn-->
                            <!--                                @click="showRequestForm = true"-->
                            <!--                                class="text-transform-none"-->
                            <!--                                color="primary"-->
                            <!--                                depressed-->
                            <!--                              >-->
                            <!--                                Request a new release for-->
                            <!--                                {{ getIOSReleaseInfo ? "Android" : "iOS" }}-->
                            <!--                              </v-btn>-->
                            <!--                            </v-col>-->
                          </v-row>
                        </v-card>
                      </v-menu>
                    </div>
                    <!--                    <div-->
                    <!--                      v-if="!getIOSReleaseInfo || !getAndroidReleaseInfo"-->
                    <!--                      @click="showRequestForm = true"-->
                    <!--                      class="-->
                    <!--                        primary&#45;&#45;text-->
                    <!--                        text-decoration-underline-->
                    <!--                        cursor-pointer-->
                    <!--                      "-->
                    <!--                    >-->
                    <!--                      Update me when available for-->
                    <!--                      {{ getIOSReleaseInfo ? "Android" : "iOS" }}-->
                    <!--                    </div>-->
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row
              v-if="
                mobile === 'ios' &&
                !getIOSReleaseInfo &&
                !get(installInfo, 'settings.status', null)
              "
            >
              <v-col class="font-size-h2 bold-text text-center">
                This release isn’t available for your platform yet
              </v-col>

              <v-col class="text-center" cols="12">
                If you have Android device, use it to open this page or scan the
                below QR code
                <figure class="qrcode">
                  <qrcode
                    :value="shareLink"
                    :options="{
                      errorCorrectionLevel: 'Q',
                      width: 200,
                    }"
                  ></qrcode>
                  <img
                    class="qrcode__image"
                    :src="getAppImage()"
                    :alt="installInfo.name"
                    @error="replaceDefaultAppImage"
                  />
                </figure>
              </v-col>
              <!--              <v-col class="text-center" cols="12">-->
              <!--                <div-->
              <!--                  @click="showRequestForm = true"-->
              <!--                  class="primary&#45;&#45;text cursor-pointer"-->
              <!--                >-->
              <!--                  Update me when available on iOS-->
              <!--                </div>-->
              <!--              </v-col>-->
            </v-row>
            <v-row
              v-if="
                mobile === 'android' &&
                !getAndroidReleaseInfo &&
                !get(installInfo, 'settings.status', null)
              "
            >
              <v-col class="titleFont bold-text text-center">
                This release isn’t available for your platform yet
              </v-col>
              <v-col class="text-center" cols="12">
                If you have iOS device, use it to open this page or scan the
                below QR code
                <figure class="qrcode">
                  <qrcode
                    :value="shareLink"
                    :options="{
                      errorCorrectionLevel: 'Q',
                      width: 200,
                    }"
                  ></qrcode>
                  <img
                    class="qrcode__image"
                    :src="getAppImage()"
                    :alt="installInfo.name"
                    @error="replaceDefaultAppImage"
                  />
                </figure>
              </v-col>
              <!--              <v-col class="text-center" cols="12">-->
              <!--                <div-->
              <!--                  @click="showRequestForm = true"-->
              <!--                  class="primary&#45;&#45;text cursor-pointer"-->
              <!--                >-->
              <!--                  Update me when available on Android-->
              <!--                </div>-->
              <!--              </v-col>-->
            </v-row>
            <v-row
              v-if="mobile == 'unknown'"
              class="dark-grey-text"
              align="center"
            >
              <v-col class="text-center" cols="12">
                <div class="text-muted small">
                  Having trouble installing? Read our
                  <span
                    @click="scrollToFAQ"
                    class="
                      text-decoration-underline
                      primary--text
                      cursor-pointer
                      body-1
                    "
                  >
                    FAQ
                  </span>
                </div>
              </v-col>
              <v-col cols="12" class="text-center">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row
              v-if="
                (mobile !== 'unknown' &&
                  mobile === 'ios' &&
                  getIOSReleaseInfo) ||
                (mobile === 'android' && getAndroidReleaseInfo)
              "
            >
              <v-col class="text-center" cols="12">
                <v-btn
                  x-large
                  :loading="busy"
                  :color="
                    mobile == 'android'
                      ? 'success lighten-1'
                      : 'primary lighten-1'
                  "
                  @click="downloadApp"
                >
                  <v-icon
                    v-text="mobile == 'android' ? 'mdi-android' : 'mdi-apple'"
                    left
                  ></v-icon>
                  Download
                </v-btn>
              </v-col>
              <!--              <v-col cols="12">-->
              <!--                <v-row align="center">-->
              <!--                  <v-col>-->
              <!--                    <v-divider></v-divider>-->
              <!--                  </v-col>-->
              <!--                  <v-col cols="auto">Or</v-col>-->
              <!--                  <v-col>-->
              <!--                    <v-divider></v-divider>-->
              <!--                  </v-col>-->
              <!--                </v-row>-->
              <!--              </v-col>-->
              <!--              <v-col class="text-center" cols="12">-->
              <!--                <v-btn-->
              <!--                  outlined-->
              <!--                  class="text-transform-none"-->
              <!--                  x-large-->
              <!--                  color="primary lighten-1"-->
              <!--                >-->
              <!--                  Install via testapp.io-->
              <!--                </v-btn>-->
              <!--              </v-col>-->
              <!--              <v-col class="text-center" cols="12">-->
              <!--                <div-->
              <!--                  @click="scrollToFAQ"-->
              <!--                  class="-->
              <!--                    text-decoration-underline-->
              <!--                    primary&#45;&#45;text-->
              <!--                    body-1-->
              <!--                    cursor-pointer-->
              <!--                  "-->
              <!--                >-->
              <!--                  Why use testapp.io ?-->
              <!--                </div>-->
              <!--              </v-col>-->
              <v-col class="text-center" cols="12">
                <div class="text-muted small">
                  Having trouble installing? Read our
                  <span
                    @click="scrollToFAQ"
                    class="
                      text-decoration-underline
                      primary--text
                      body-1
                      cursor-pointer
                    "
                  >
                    FAQ
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-row
              v-if="installInfo.screenshots && installInfo.screenshots.length"
            >
              <v-col cols="12">
                <div class="screenShotContainer">
                  <div
                    v-for="(item, index) in installInfo.screenshots"
                    :key="index"
                    style="display: flex"
                  >
                    <v-sheet class="imageBorder mr-2">
                      <v-img
                        style="border-radius: 20px"
                        :src="item"
                        width="250"
                        cover
                        height="500"
                      ></v-img>
                    </v-sheet>
                  </div>
                </div>
              </v-col>
            </v-row>
            <!--            <v-row justify="center" class="mb-4">-->
            <!--              <v-col class="text-center" cols="12" md="11">-->
            <!--                -->
            <!--              </v-col>-->
            <!--            </v-row>-->
            <v-row justify="center">
              <!--              <v-col cols="12" md="11">-->
              <!--                <v-card outlined color="" class="mt-5 py-4">-->
              <!--                  <v-row justify="center">-->
              <!--                    <v-col class="pb-0 cardTitleFont " cols="10">-->
              <!--                      Update me-->
              <!--                    </v-col>-->
              <!--                    <v-col class="bodyFont pb-0" cols="11" md="10">-->
              <!--                      Want to know when the app updates? We got you covered!-->
              <!--                      Leave us your email and we’ll make sure you won’t miss-->
              <!--                      anything. And don’t worry, we promise no to send any spam!-->
              <!--                    </v-col>-->
              <!--                    <v-col class="text-center">-->
              <!--                      <v-btn-->
              <!--                        v-if="!subscribedMessage"-->
              <!--                        color="primary"-->
              <!--                        outlined-->
              <!--                        class="text-transform-none"-->
              <!--                        depressed-->
              <!--                        @click="openSubscribeModal = true"-->
              <!--                      >-->
              <!--                        Subscribe for updates-->
              <!--                      </v-btn>-->
              <!--                      <v-btn-->
              <!--                        v-if="subscribedMessage"-->
              <!--                        color="success"-->
              <!--                        class="text-transform-none"-->
              <!--                        depressed-->
              <!--                      >-->
              <!--                        {{ subscribedMessage }}-->
              <!--                      </v-btn>-->
              <!--                    </v-col>-->
              <!--                  </v-row>-->
              <!--                </v-card>-->
              <!--              </v-col>-->
              <v-col cols="12" md="12">
                <div class="font-size-h2 mb-3 bold-text">Instructions</div>
                <vue-readmore
                  :text="installInfo.instructions || '-'"
                  :length="200"
                ></vue-readmore>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-if="mobile === 'ios' && getIOSReleaseInfo"
                cols="12"
                md="12"
              >
                <div class="font-size-h2 bold-text">Release Update</div>
                <vue-readmore
                  :text="getIOSReleaseInfo.note || '-'"
                  :length="200"
                ></vue-readmore>
              </v-col>
              <v-col
                v-if="mobile === 'android' && getAndroidReleaseInfo"
                cols="12"
                md="12"
              >
                <div class="font-size-h2 bold-text">Release Update</div>
                <vue-readmore
                  :text="getAndroidReleaseInfo.note || '-'"
                  :length="200"
                ></vue-readmore>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-if="mobile === 'unknown' && !noReleases"
                cols="12"
                md="11"
              >
                <feedback-form></feedback-form>
              </v-col>
              <v-col
                v-if="mobile === 'android' && getAndroidReleaseInfo"
                cols="12"
                md="10"
              >
                <feedback-form
                  :releaseInfo="getAndroidReleaseInfo"
                ></feedback-form>
              </v-col>
              <v-col
                v-if="mobile === 'ios' && getIOSReleaseInfo"
                cols="12"
                md="10"
              >
                <feedback-form :releaseInfo="getIOSReleaseInfo"></feedback-form>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col class="py-10" cols="10">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-if="['android', 'unknown'].includes(mobile)"
                cols="12"
                md="6"
              >
                <v-row>
                  <v-col cols="12">
                    <div class="bodyFont text-muted">
                      <v-icon
                        size="20"
                        right
                        color="grey"
                        v-text="'mdi-android'"
                      ></v-icon>

                      Android
                    </div>
                  </v-col>
                  <v-col v-if="!getAndroidReleaseInfo" cols="12">
                    Not available
                  </v-col>
                  <template v-else>
                    <v-col cols="6">
                      <div class="bold-text mb-2">Store</div>
                      <div>
                        <a
                          :href="
                            get(installInfo, 'settings.store_links.android', '')
                          "
                        >
                          <MaxText
                            :text="
                              get(
                                installInfo,
                                'settings.store_links.android',
                                ''
                              )
                            "
                            max="18"
                          />
                        </a>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div>
                        <div class="bold-text">Package</div>
                        <div class="dark-grey-text">
                          <MaxText
                            :text="getAndroidReleaseInfo.package"
                            max="15"
                          />
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="">
                        <div class="bold-text mb-2">Version</div>
                        <div class="dark-grey-text">
                          <MaxText
                            :text="getAndroidReleaseInfo.version"
                            max="18"
                          />
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="">
                        <div class="bold-text mb-2">Version Code</div>
                        <div class="dark-grey-text">
                          <MaxText
                            :text="getAndroidReleaseInfo.version_code"
                            max="18"
                          />
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="bold-text mb-2">Size</div>
                      <div class="dark-grey-text">
                        {{ formatBytes(getAndroidReleaseInfo.size) }}
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="">
                        <div class="bold-text mb-2">Released</div>
                        <div class="dark-grey-text">
                          <vue-hoverable-date
                            :date="getAndroidReleaseInfo.updated_at"
                          ></vue-hoverable-date>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="bold-text mb-2">Minimum SDK Version</div>
                      <div class="dark-grey-text">
                        {{
                          get(getAndroidReleaseInfo, "info.min_sdk_version", "")
                        }}
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="bold-text mb-2">Target SDK Version</div>
                      <div class="dark-grey-text">
                        {{
                          get(
                            getAndroidReleaseInfo,
                            "info.target_sdk_version",
                            ""
                          )
                        }}
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="bold-text mb-2">Permissions</div>
                      <div
                        v-if="
                          getAndroidReleaseInfo &&
                          getAndroidReleaseInfo.info &&
                          getAndroidReleaseInfo.info.permissions.length
                        "
                        @click="showPermissionDialog"
                        class="primary--text cursor-pointer"
                      >
                        View permissions
                      </div>
                      <div v-else>-</div>
                    </v-col>
                  </template>
                </v-row>
              </v-col>
              <v-col
                v-if="['ios', 'unknown'].includes(mobile)"
                cols="12"
                md="6"
              >
                <v-row>
                  <v-col class="" cols="12">
                    <div class="bodyFont text-muted">
                      <v-icon
                        size="20"
                        class="mb-1"
                        right
                        color="grey"
                        v-text="'mdi-apple'"
                      ></v-icon>

                      iOS
                    </div>
                  </v-col>
                  <v-col v-if="!getIOSReleaseInfo" class="" cols="12">
                    Not available
                  </v-col>
                  <template v-else-if="getIOSReleaseInfo.testflight">
                    <v-col>
                      <a :href="getIOSReleaseInfo.testflight"> Click here </a>
                      to start testing in TestFlight
                    </v-col>
                  </template>
                  <template v-else>
                    <v-col cols="6">
                      <div class="bold-text mb-2">Store</div>
                      <div>
                        <a
                          :href="
                            get(installInfo, 'settings.store_links.ios', '')
                          "
                        >
                          <MaxText
                            :text="
                              get(installInfo, 'settings.store_links.ios', '')
                            "
                            max="18"
                          />
                        </a>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div>
                        <div class="bold-text">Package</div>
                        <div class="dark-grey-text">
                          <MaxText :text="getIOSReleaseInfo.package" max="15" />
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="">
                        <div class="bold-text mb-2">Version</div>
                        <div class="dark-grey-text">
                          <MaxText :text="getIOSReleaseInfo.version" max="18" />
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="">
                        <div class="bold-text mb-2">Version Code</div>
                        <div class="dark-grey-text">
                          <MaxText
                            :text="getIOSReleaseInfo.version_code"
                            max="18"
                          />
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="bold-text mb-2">Size</div>
                      <div class="dark-grey-text">
                        {{ formatBytes(getIOSReleaseInfo.size) }}
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="">
                        <div class="bold-text mb-2">Released</div>
                        <div class="dark-grey-text">
                          <vue-hoverable-date
                            :date="getIOSReleaseInfo.updated_at"
                          ></vue-hoverable-date>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="">
                        <div class="bold-text mb-2">Supported Platforms</div>
                        <div
                          v-for="(item, sindex) in get(
                            getIOSReleaseInfo,
                            'info.supported_platforms',
                            []
                          )"
                          :key="sindex"
                          class="dark-grey-text"
                        >
                          {{ item }}
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="">
                        <div class="bold-text mb-2">Minimum OS Version</div>
                        <div class="dark-grey-text">
                          {{
                            get(
                              getIOSReleaseInfo,
                              "info.minimum_os_version",
                              ""
                            )
                          }}
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="">
                        <div class="bold-text mb-2">Platform Version</div>
                        <div class="dark-grey-text">
                          {{
                            get(getIOSReleaseInfo, "info.platform_version", "-")
                          }}
                        </div>
                      </div>
                    </v-col>
                  </template>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12">
                <div class="bodyFont bold-text text-muted">General Info</div>
              </v-col>
              <v-col cols="6" md="4">
                <div class="bold-text mb-2">Developer</div>
                <div class="mb-2">
                  {{ get(installInfo, "settings.developer_contact.name", "") }}
                </div>
                <div class="mb-2">
                  {{ get(installInfo, "settings.developer_contact.email", "") }}
                </div>
                <div>
                  <a
                    v-if="
                      get(installInfo, 'settings.developer_contact.website', '')
                    "
                    :href="
                      get(installInfo, 'settings.developer_contact.website', '')
                    "
                  >
                    Website
                  </a>
                </div>
              </v-col>
              <v-col cols="6" md="4">
                <div class="bold-text mb-2">App Legal</div>
                <div
                  v-if="
                    get(installInfo, 'settings.developer_contact.terms', '')
                  "
                  class="mb-2"
                >
                  <a
                    v-if="
                      get(installInfo, 'settings.developer_contact.terms', '')
                    "
                    :href="
                      get(installInfo, 'settings.developer_contact.terms', '')
                    "
                  >
                    Terms & Conditions
                  </a>
                </div>
                <div
                  v-if="
                    get(installInfo, 'settings.developer_contact.privacy', '')
                  "
                >
                  <a
                    v-if="
                      get(installInfo, 'settings.developer_contact.privacy', '')
                    "
                    :href="
                      get(installInfo, 'settings.developer_contact.privacy', '')
                    "
                  >
                    Privacy policy
                  </a>
                </div>
                <div
                  v-if="
                    !get(
                      installInfo,
                      'settings.developer_contact.privacy',
                      ''
                    ) &&
                    !get(installInfo, 'settings.developer_contact.terms', '')
                  "
                >
                  Not provided
                </div>
              </v-col>
            </v-row>
            <v-row id="releaseFaq" justify="center" class="mt-8 faq">
              <v-col cols="12" lg="12" class="questions">
                <h1 class="font-size-h2 bold-text">FAQ</h1>
                <br />
                <v-expansion-panels flat>
                  <template v-if="['android', 'unknown'].includes(mobile)">
                    <v-expansion-panel>
                      <v-expansion-panel-header class="py-2 px-4">
                        <div class="font-size-h3 font-weight-light">
                          While installing the app on Android, I get a security
                          warning
                        </div>
                        <template #actions>
                          <v-icon left large class="ml-4">expand_more</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div>
                          On Android 8 or higher, you get a dialog such as
                          <b
                            >"Chrome, For your security, your phone is not
                            allowed to install unknown apps from this source"</b
                          >. <br /><br />
                          Click the <b>Settings</b> button of that dialog and
                          toggle <b>Allow from this source</b>. <br /><br />

                          Alternatively you can navigate to
                          <b>Phone Settings > Apps & notifications</b>.
                          <br /><br />
                          Find the browser application in the list, tap
                          <b>Advanced</b> then <b>Install unknown apps</b> then
                          toggle <b>Allow from this source</b>. <br /><br />
                          On Android 7 or lower, navigate to the device
                          <b>Settings</b> then into the
                          <b>Security</b> category. Under the
                          <b>Device Administration</b> category, toggle
                          <b>Unknown Sources</b>. Then try to install the
                          application again. Please note that some device
                          manufacturers might customize the settings UI and thus
                          menu entries can have different names or be at a
                          different location.
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="py-2 px-4">
                        <div class="font-size-h3 font-weight-light">
                          While installing the app, I get a "There Was a Problem
                          Parsing the Package" error
                        </div>
                        <template #actions>
                          <v-icon left large class="ml-4">expand_more</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div>
                          This error could be caused by one of the following
                          reasons:
                          <br />
                          <br />
                          <ul>
                            <li>File may be downloaded incompletely</li>
                            <li>
                              Application might be not suitable for your
                              hardware or OS version
                            </li>
                            <li>Security settings</li>
                            <li>Corrupted APK file</li>
                            <li>
                              Name of the package is changed after signing
                            </li>
                          </ul>
                          <br />
                          <p>
                            To troubleshoot, try reinstalling the app. If that
                            doesn't work, contact the app developer to help
                            resolve the issue.
                          </p>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </template>
                  <template v-if="['ios', 'unknown'].includes(mobile)">
                    <v-expansion-panel>
                      <v-expansion-panel-header class="py-2 px-4">
                        <div class="font-size-h3 font-weight-light">
                          I get the message “Untrusted Enterprise Developer”
                        </div>
                        <template #actions>
                          <v-icon left large class="ml-4">expand_more</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div>
                          In order for the app to be available for use, you will
                          need to trust the developer's certificate.
                          <br /><br />
                          From your phone’s home screen, tap
                          <b
                            >Settings > General > Profiles or Profiles & Device
                            Management</b
                          >. <br />Under the <b>Enterprise App</b> heading, you
                          see will see a profile for the developer. Tap the name
                          of the developer profile and then confirm you trust
                          them. You should now be able to launch the app.
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header class="py-2 px-4">
                        <div class="font-size-h3 font-weight-light">
                          While installing the app, I get an “Unable to Download
                          App” error, and the app is not installed
                        </div>
                        <template #actions>
                          <v-icon left large class="ml-4">expand_more</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div>
                          This error could be caused by many reasons, and you
                          should contact the app developer to help resolve the
                          issue.<br /><br />
                          If you are the app developer, this error could be
                          caused by one of the following reasons: <br />

                          <ul>
                            <li>
                              UDID is not included in the provisioning profile
                            </li>
                            <li>
                              The build was incorrectly signed or has broken
                              entitlements
                            </li>
                            <li>
                              Device or iOS version is incompatible with the
                              build
                            </li>
                            <li>Device storage is full</li>
                            <li>Actual download error</li>
                            <li>
                              App is over 50 MB and downloaded over a cellular
                              connection
                            </li>
                          </ul>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header class="py-2 px-4">
                        <div class="font-size-h3 font-weight-light">
                          After installing the app, It appears to download
                          successfully but I am unable to open it
                        </div>
                        <template #actions>
                          <v-icon left large class="ml-4">expand_more</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div>
                          It could be because your UDID is missingg from
                          provisioning profile. <br />

                          Go to <a href="/udid">install UDID page</a> in safari
                          and continue from there
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header class="py-2 px-4">
                        <div class="font-size-h3 font-weight-light">
                          When I press the “Install” button, the installation
                          alert never shows up and the app never installs on my
                          device
                        </div>
                        <template #actions>
                          <v-icon left large class="ml-4">expand_more</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div>
                          If you have previously installed the app from the App
                          Store, and it carries the same version of the app that
                          is being installed, there will be no alert to confirm
                          the installation.<br /><br />
                          As a workaround, please try uninstalling the existing
                          version of the app, and then tap the Install button
                          again.
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </template>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <Modal v-model="qrcodeModal" @close="qrcodeModal = false">
        <template #message>
          <h1 class="text-center pa-5">Install</h1>
          <h2 class="text-center">{{ installInfo.name }}</h2>
          <div class="text-center pt-5">
            <div v-if="getIOSReleaseInfo">Avaliable for iOS</div>
            <div v-if="getAndroidReleaseInfo">Avaliable for Android</div>
          </div>
          <v-row>
            <v-col class="pr-0  pb-0 " cols="12">
              <div class="text-center">
                <figure class="qrcode">
                  <qrcode
                    :value="shareLink"
                    :options="{
                      errorCorrectionLevel: 'Q',
                      width: 200,
                    }"
                  ></qrcode>
                  <img
                    class="qrcode__image"
                    :src="getAppImage()"
                    :alt="installInfo.name"
                    @error="replaceDefaultAppImage"
                  />
                </figure>
                <div class="bodyFont mt-0 mb-4">
                  Scan QR code from your mobile device to install the app
                </div>
              </div>
              <v-divider></v-divider>
              <div class="text-muted mt-4">
                By installing, your location (country & city) and device
                information (model & version) will be shared with the developers
                of this app to help them to address your issues better.
              </div>
            </v-col>
          </v-row>
        </template>
      </Modal>
      <Modal
        v-model="openPermissionModal"
        @close="openPermissionModal = false"
        :fullscreen="$vuetify.breakpoint.smAndDown"
      >
        <template #message>
          <v-row v-if="openPermissionModal" justify="center">
            <v-col cols="12" class="mt-4 text-center">
              <div class="titleFont bold-text">
                {{ installInfo.name }}
              </div>
              <div class="mt-4 bodyFont">
                <span class="text-muted mr-2">By</span>
                <span class="dark-grey-text">{{
                  get(installInfo, "settings.developer_contact.name", "")
                }}</span>
              </div>
              <div class="my-5 caption text-muted">
                App include the following permissions
              </div>
            </v-col>
            <v-col cols="12" class="black--text scrollable">
              <div
                v-if="
                  getAndroidReleaseInfo &&
                  getAndroidReleaseInfo.info &&
                  getAndroidReleaseInfo.info.permissions.length
                "
              >
                <div
                  v-for="(item, pindex) in getAndroidReleaseInfo.info
                    .permissions"
                  :key="pindex"
                >
                  {{ item.name }}
                </div>
              </div>
            </v-col>
          </v-row>
        </template>
      </Modal>
      <Modal v-model="showRequestForm" @close="showRequestForm = false">
        <template #message>
          <release-request-form
            :android="getAndroidReleaseInfo"
            :ios="getIOSReleaseInfo"
          ></release-request-form>
        </template>
      </Modal>
      <Modal v-model="openSubscribeModal" @close="openSubscribeModal = false">
        <template #message>
          <subscribe-form
            v-if="openSubscribeModal"
            @close="openSubscribeModal = false"
            @success="subscribedMessage = $event"
          ></subscribe-form>
        </template>
      </Modal>
      <Modal v-model="openIosInstallHelp">
        <template #message>
          <v-card-title class="font-weight-bold"
            >Progress in background</v-card-title
          >
          <v-card-text class="message">
            Once you tap on install button, you can check the progress in your
            <b>home screen</b>.
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn @click="openIosInstallHelp = false" class="main primary">
              Ok
            </v-btn>
          </v-card-actions>
        </template>
      </Modal>
    </v-container>
    <div class="mt-5 mb-12 text-center">
      <div>
        <div class="mb-5">
          <a href="https://testapp.io">
            <img src="/media/logos/logo_dark.svg" height="30" />
          </a>
        </div>
        <div class="text-muted">
          By installing this app you're agreeing with TestApp.io
          <br />
          <a
            href="https://testapp.io/privacy-policy"
            class="black--text text-decoration-underline"
            >Privacy Policy
          </a>
          &nbsp; and &nbsp;
          <a
            href="https://testapp.io/terms-and-conditions"
            class="black--text text-decoration-underline"
            >Terms &amp; Conditions</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_CURRENT_TIMESTAMP,
  GET_NEW_INSTALL_APP_INFO,
} from "@/store/releases/install.module";
import { get, formatBytes, isEmpty } from "@/core/services/helper.service";
// import AppImage from "@/view/components/App/Image.vue";
import FeedbackForm from "@/view/components/Releases/FeedbackForm";
import ReleaseRequestForm from "@/view/components/Releases/ReleaseRequestForm";
// import CopyLink from "@/view/components/Common/CopyLink";
import VueReadmore from "@/view/components/Common/VueReadmore";
import veeValidate from "@/mixins/veeValidate";
import SubscribeForm from "@/view/components/Releases/SubscribeForm";
export default {
  components: {
    SubscribeForm,
    VueReadmore,
    // CopyLink,
    ReleaseRequestForm,
    FeedbackForm,
    // AppImage,
  },
  mixins: [veeValidate],
  props: {
    fromExpress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      platform: "android",
      openPermissionModal: false,
      subscribeEmail: "",
      subscribedMessage: "",
      openIosInstallHelp: false,
      openSettingModal: false,
      openSubscribeModal: false,
      busy: false,
      qrcodeModal: false,
      formatBytes,
      isEmpty,
      form: {},
      readMore: false,
      get,
      slider: [
        "red",
        "green",
        "orange",
        "blue",
        "pink",
        "purple",
        "indigo",
        "cyan",
        "deep-purple",
        "light-green",
        "deep-orange",
        "blue-grey",
      ],
      mobile: "",
      copied: false,
      showRequestForm: false,
      loading: false,
      errorMessage: "",
      installInfo: {},
    };
  },
  computed: {
    shareLink() {
      return `${location.origin}/apps/install/${this.$route.params.uid}`;
      // if (process.env.VUE_APP_ENV !== "production") {
      // } else {
      // return `https://testapp.io/apps/install/${this.$route.params.uid}`;
      // }
    },
    getAndroidReleaseInfo() {
      return this.installInfo.android;
    },
    getIOSReleaseInfo() {
      return this.installInfo.ios;
    },
    noReleases() {
      return (
        isEmpty(this.getAndroidReleaseInfo) && isEmpty(this.getIOSReleaseInfo)
      );
    },
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 4;
      }

      if (this.$vuetify.breakpoint.lg) {
        return 3;
      }

      if (this.$vuetify.breakpoint.md) {
        return 2;
      }

      return 1;
    },
  },
  created() {
    this.mobile = this.getMobileOperatingSystem;
    this.getInstallAppInfo();
    this.$options.metaInfo.title = this.installInfo.name;
  },
  metaInfo() {
    return {
      title: `${
        this && this.installInfo ? this.installInfo.name || "..." : "..."
      } - Testapp.io`,
      meta: [
        {
          name: "description",
          content: `${
            this.installInfo && this.installInfo.description
              ? this.installInfo.description.replace(/\n/g, " ")
              : "App Description"
          }`,
        },
        {
          name: "og:image",
          content: `${
            this.installInfo && this.installInfo.image
              ? this.installInfo.image
              : ""
          }`,
        },
        {
          name: "og:description",
          content: `${
            this.installInfo && this.installInfo.description
              ? this.installInfo.description.replace(/\n/g, " ")
              : "App Description"
          }`,
        },
        {
          name: "twitter:description",
          content: `${
            this.installInfo && this.installInfo.description
              ? this.installInfo.description.replace(/\n/g, " ")
              : "App Description"
          }`,
        },
        // {
        //   name: "robots",
        //   content: "noindex,nofollow"
        // }
      ],
    };
  },
  methods: {
    getInstallAppInfo() {
      this.errorMessage = null;
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_NEW_INSTALL_APP_INFO, { id: this.$route.params.uid })
        .then((response) => {
          this.loading = false;
          this.installInfo = response.data;

          this.installInfo.ios = isEmpty(this.installInfo.ios)
            ? null
            : this.installInfo.ios;
          this.installInfo.android = isEmpty(this.installInfo.android)
            ? null
            : this.installInfo.android;
        })
        .catch((err) => {
          this.loading = false;
          this.errorMessage = err.message;
        });
    },
    getFeedbackInfo() {
      if (this.mobile !== "unknown") {
        if (this.mobile == "android") {
          return this.getAndroidReleaseInfo;
        }
        if (this.mobile == "ios") {
          return this.getIOSReleaseInfo;
        }
      } else {
        return null;
      }
    },
    showPermissionDialog() {
      this.openPermissionModal = true;
    },
    scrollToFAQ() {
      let elmnt = document.getElementById("releaseFaq");
      elmnt.scrollIntoView();
    },
    downloadApp() {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(GET_CURRENT_TIMESTAMP)
        .then((response) => {
          this.busy = false;
          //@NOTE: DON'T CHANGE share_id to shareID
          const query = `?share_id=${this.$route.params.uid}&t=${
            response.time || ""
          }`;
          if (this.mobile === "android") {
            window.open(this.getAndroidReleaseInfo.url + query, "_self");
          }
          if (this.mobile === "ios") {
            if (this.getIOSReleaseInfo.testflight) {
              window.open(this.getIOSReleaseInfo.testflight);
            } else {
              window.location.replace(
                `itms-services://?action=download-manifest&url=${encodeURIComponent(
                  this.getIOSReleaseInfo.url + query
                )}`
              );
              setTimeout(() => {
                this.openIosInstallHelp = true;
              }, 2000);
            }
          }
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(err.message);
        });
    },
    getPlatformStatus() {
      if (get(this.installInfo, "settings.status", null)) {
        return false;
      } else {
        if (this.mobile === "android") {
          return this.getAndroidReleaseInfo;
        } else if (this.mobile === "ios") {
          return this.getIOSReleaseInfo;
        } else {
          return this.getAndroidReleaseInfo || this.getIOSReleaseInfo;
        }
      }
    },
    getAppImage() {
      if (this.installInfo.image) {
        return this.installInfo.image;
      } else {
        if (this.getAndroidReleaseInfo && this.getAndroidReleaseInfo.image) {
          return this.getAndroidReleaseInfo.image;
        } else if (this.getIOSReleaseInfo && this.getIOSReleaseInfo.image) {
          return this.getIOSReleaseInfo.image;
        } else {
          return "/media/defaults/icon-100.png";
        }
      }
    },
  },
};
</script>

<style scoped>
.errImg {
  max-width: 100%;
}

.qrcode {
  display: inline-block;
  font-size: 0;
  margin-bottom: 0;
  position: relative;
}

.qrcode__image {
  background-color: #fff;
  border: 0.25rem solid #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  height: 15%;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 15%;
}

.screenShotContainer {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  white-space: nowrap;
}

/*.screenShotContainer {*/
/*  overflow-y: scroll;*/
/*  display: flex;*/
/*  height: 500px;*/
/*  white-space: nowrap;*/
/*  overflow-x: scroll;*/
/*  overflow-y: hidden;*/
/*  scrollbar-width: thin;*/
/*}*/
.scrollable {
  overflow-y: auto;
  -webkit-overflow-scrolling: unset;
  height: 470px;
}
.imageBorder {
  border-radius: 22px;
  margin-right: 24px;
  border: 0.5px solid rgba(0, 0, 0, 0.08);
}
</style>
